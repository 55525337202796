<template>
  <div class="container-fluid py-2">
    <b-card no-body
            class="shadow"
    >
      <b-card-header class="bg-primary text-white py-2">
        <h5 class="mb-0 text-white">
          Task Progress Overview
        </h5>
      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          striped
          hover
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
          small
        >
          <template #cell(progress)="data">
            <div class="d-flex">
              <div class="mt-1 mr-1">
                {{ data.item.current }}/{{ data.item.total }}
              </div>
              <div class="w-100">
                <b-progress
                  :value="data.value"
                  :max="100"
                  show-value
                  :variant="getProgressVariant(data.value)"
                  class="my-1"
                  height="1.5rem"
                />
              </div>
            </div>
          </template>
          <template #cell(remarks)="data">
            <div class="remarks-container">
              <div class="remarks-preview">
                {{ truncateText(data.value) }}
              </div>
              <b-button v-if="data.value.length > 50"
                        size="sm"
                        variant="link"
                        class="show-more-btn"
                        @click="showRemarks(data.value)"
              >
                Show More
              </b-button>
            </div>
          </template>
          <template #cell(startedAt)="data">
            {{ data.value }}
          </template>
          <template #cell(finishedAt)="data">
            {{ data.value ? data.value : '-' }}
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!-- Modal for full remarks -->
    <b-modal v-model="showModal"
             title="Full Remarks"
             ok-only
    >
      <vue-json-pretty :data="fullRemarks" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BProgress, BButton, BModal,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BProgress,
    BButton,
    BModal,
    VueJsonPretty,
  },
  data() {
    return {
      fields: [
        {
          key: 'name', label: 'Task Name', sortable: true, class: 'col-1',
        },
        { key: 'progress', label: 'Progress' },
        { key: 'remarks', label: 'Remarks', class: 'col-2' },
        {
          key: 'startedAt', label: 'Started At', sortable: true, class: 'col-1',
        },
        {
          key: 'lastUpdated', label: 'Last Updated', sortable: true, class: 'col-1',
        },
        {
          key: 'finishedAt', label: 'Finished At', sortable: true, class: 'col-1',
        },
        {
          key: 'exception', label: 'Exception', sortable: true, class: 'col-1 text-danger',
        },
      ],
      items: [
      ],
      showModal: false,
      fullRemarks: '',
    }
  },
  mounted() {
    this.getCommandProcess()
  },
  methods: {
    getCommandProcess() {
      useJwt.getCommandProcess().then(response => {
        this.items = response.data.data.data.map(item => ({
          name: item.name,
          progress: this.number_format((item.progress / item.total) * 100, 4),
          total: item.total,
          current: item.progress,
          remarks: JSON.stringify(item.remarks || {}),
          startedAt: this.FROM_NOW(item.created_at),
          lastUpdated: this.FORMAT_DATE(item.updated_at, 'MMM Do YYYY, H:mm:ss'),
          finishedAt: item.finished_at ? this.FORMAT_DATE(item.finished_at, 'MMMM Do YYYY, h:mm:ss a') : null,
          exception: item.exception,
        }))
      })
    },
    getProgressVariant(value) {
      if (value < 30) return 'danger'
      if (value < 70) return 'warning'
      return 'success'
    },
    formatDate(dateString) {
      const options = {
        month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit',
      }
      return new Date(dateString).toLocaleDateString('en-US', options)
    },
    truncateText(text, length = 50) {
      return text.length > length ? `${text.substring(0, length)}...` : text
    },
    showRemarks(remarks) {
      this.fullRemarks = remarks
      this.showModal = true
    },
  },
}
</script>

  <style scoped>
  .card {
    border: none;
  }
  .card-header {
    border-bottom: none;
  }
  .table th, .table td {
    padding: 0.5rem;
  }
  .remarks-container {
    display: flex;
    flex-direction: column;
    max-width: 250px;
  }
  .remarks-preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .show-more-btn {
    align-self: flex-start;
    padding: 0;
    margin-top: 0.25rem;
  }
  .remarks-full {
    white-space: pre-wrap;
    word-break: break-word;
  }
  </style>
